import {Fab} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import Div100vh from 'react-div-100vh';

import './fab-create.styles.scss';

export default function FabCreate({href}: {href?: string | null}) {
    function goToCreateHome() {
        window.location.href = href || '/started';
    }

    return (
        <Div100vh className='dj3n-fab'>
            <Fab className="dj3n-fab-action click" aria-label="create" onClick={goToCreateHome}>
                <AddIcon />
            </Fab>
        </Div100vh>
    );
}

export function FabEmpty() {
  return (
    <Div100vh className='dj3n-fab'>
    </Div100vh>
  );
}
