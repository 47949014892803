export default {
  home: '/',
  account: '/account',
  profile: {
    view: '/profile',
    edit: '/profile/edit',
    editLinks: '/profile/edit/links',
    vote: '/profile/vote',
  },
  invite: {
    base: '/invite',
    create: '/invite/create',
    claim: (uuid?: string) => `/invite/${uuid || ''}`
  },
  browse: {
    profile: {
      vote: {
        byHandle: (s: string) => `/3/${s}/vote`,
        byUuid: (s: string) => `/profile/${s}/vote`,
      },
    },
    botb: {
      discover: '/browse/botb/discover',
      latest: '/browse/botb/latest',
    },
    trending: '/browse/trending',
    discover: '/browse/discover',
    latest: '/browse/latest',
  },
  logout: '/logout',
  login: '/auth',
  botb: {
    index: '/botb',
    signup: '/botb/create',
    signupWithExisting: `/botb/register`,
  },
  external: {
    botb: {
      home: 'https://battleofthebands.com/',
      buyTickets: 'https://losthighwayshow.com/tickets/',
      termsOfService: 'https://battleofthebands.com/rules.html',
    },
    docs: 'https://dj3n.notion.site/Knowledge-Base-Beta-5ad153057b0245f1b9d7aa7fac3937d7',
    phishing: 'https://dj3n.notion.site/Guard-against-Phishing-Attacks-83e577a8e02c43af962ff47f1e15eb39',
  },
}