import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { verifyLogin } from "../../utils/communicator";

import Logo from "../../assets/imgs/dj3n-logo-and-typeface.png";
import "./sms-login-verify.styles.scss";
import Frame from "../../components/frame";

export default function SmsLoginVerify() {
  const [isPending, setIsPending] = useState(false);
  const [message, setMessage] = useState(<></>);
  const [redirectUri, setRedirectUri] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
      if (isPending) return
      setIsPending(true)

    // Standard params
    const params = new URLSearchParams(window.location.search);
    const signature = params.get("signature");
    const address = params.get("address");
    const messageHash = params.get("messageHash");
    const error = params.get("error");
    const cancelled = params.get("cancelled");
    // We added this one for convenience
    const redirect = decodeURI(params.get("redirect"));
    const redirectParams = new URLSearchParams(redirect)
    const destinationPageName = redirectParams.get("destinationPageName")
    const destinationPageUrl = redirectParams.get("destinationPageUrl")
    setRedirectUri(destinationPageUrl || '/profile');

    verifyLogin({ signature, messageHash, address, error, cancelled })
      .then((data) => {
        if (!data) {
          setMessage("User not logged in, check the console for details");
          return;
        }

        setMessage(
          <p>Authentication successful. Standby.</p>
        );

        navigate(destinationPageUrl ? destinationPageUrl : `/profile`);
      })
      .catch((err) => {
        console.error(err);
          setMessage(<p>An error has occurred,<br/> check the console for details.</p>);
      })
      .finally(() => setIsPending(false));
  }, [setIsPending, setRedirectUri, navigate]);

  return (
    <Frame className='login-verify'>
        <div className='logo'><img src={Logo} /><span className='typeface-logo'></span></div>
        <div className="login-verify__body">
        <h3>
        <p>Authenticating...</p>
        </h3>
        <br />
        <p>{message}</p>
        {redirectUri ? <a href={redirectUri}>Continue</a> : null}
        </div>
    </Frame>
  );
};
