import './vote-widget.styles.scss'
import Button, {CancelButton} from "../button/button.component";
import React, {ChangeEvent, useEffect, useState} from "react";
import {getAvailableVotes, getProfileByUuid, updateUser, vote, voteForProfile, profile as commProfile} from "../../utils/communicator";
import {Avatar, Popover} from "@mui/material";
import Profile, {Event, BattleOfTheBandsEventType} from "../../types/profile.types";
import Vote from "../../types/vote.types";
import Typography from "@mui/material/Typography";
import {whoamiResponseType} from "../../types";
import Modal from "../modal/modal.component";
import TextField from "../mui/text-field";
import {validateEmail} from "../../utils/utils";

import VotedImage from '../../assets/imgs/botb/post-vote.png'
import LHLogoSmall from '../../assets/imgs/botb/lost-highway-logo-small.jpg'
import routes from "../../routes";

function EmailAndTermsModal({defaultEmail, visible, onCancel, onSubmit}: {defaultEmail?: string, visible: boolean, onCancel: () => any, onSubmit: (email: string) => any}) {
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(false)

  useEffect(() => {
    const b = validateEmail(email || defaultEmail || '')
    setIsEmailValid(b)
  }, [email, defaultEmail, setIsEmailValid])

  function changeEmail(evt: ChangeEvent<HTMLInputElement>) {
    setEmail(evt.target.value)
  }

  function doSubmit() {
    onSubmit(email || defaultEmail || '')
  }

  return (
    <Modal className='email-modal' visible={visible} onCancel={onCancel}>
      <div>
        <TextField type='email' placeholder='you@example.com' onChange={changeEmail} value={email || defaultEmail} />
        <p className='subtext'>By voting, you agree to the contest <a href={routes.external.botb.termsOfService} target='_blank'>terms and conditions</a>.</p>
        <div className='actions'>
          <CancelButton label='Cancel' onClick={onCancel} />
          <Button className='primary-action' label='Vote' onClick={doSubmit} disabled={!isEmailValid} />
        </div>
      </div>
    </Modal>
  )
}

export function VoteButton({profile, whoami, label, className, btnClass}: {profile: string, whoami: whoamiResponseType, label?: string, className?: string, btnClass?: string}) {
  const [hasVote, setHasVote] = useState(true)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [voteTally, setVoteTally] = useState<string>('...');
  const [expanded, setExpanded] = useState(false);
  const [manualValidation, setManualValidation] = useState(false)
  const [emailModalVisible, setEmailModalVisible] = useState(false)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const isAuthenticated = whoami?.user.uuid;

  useEffect(() => {
    getAvailableVotes().then((c) => {
      const b = c > 0
      setHasVote(b)
    })
  }, [setHasVote])

  useEffect(() => {
    vote.getTally(profile).then((r) => {
      setVoteTally(r.toString())
    })
  }, [profile, setVoteTally])

  function executeVote() {
    voteForProfile(profile)
      .then(() => {
        setHasVote(false)
        setExpanded(true)
        return vote.getTally(profile)
      })
      .then((tally) => {
        setVoteTally(tally.toString())
      })
      .catch((e) => {
        console.error(e)
        alert('Something went wrong during voting. Please refresh and try again.')
      });
  }

  function doVote() {
    if (!isAuthenticated) {
      console.log('User is missing');
      const url = encodeURIComponent(window.location.pathname);
      const page = 'Vote'
      window.location.href = `/auth?url=${url}&page=${page}`;
      return;
    }
    if (!manualValidation && (!whoami.user.email || !whoami.profile.events?.includes(Event.BATTLE_OF_THE_BANDS_2023))) {
      setEmailModalVisible(true)
      return
    }
    executeVote();
  }

  function handleAlreadyVotedOnClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  function onCancelEmailModal() {
    setEmailModalVisible(false)
  }

  function onSubmitEmailModal(email: string) {
    async function run() {
      // Update email in user profile
      if (whoami?.user.email !== email) {
        await updateUser({uuid: whoami?.user.uuid!, email})
      }
      // Subscribe to event (if applicable)
      if (!whoami?.profile.events?.includes(Event.BATTLE_OF_THE_BANDS_2023)) {
        await commProfile.event.register(Event.BATTLE_OF_THE_BANDS_2023, {
          type: BattleOfTheBandsEventType.FAN,
        })
      }
      setManualValidation(true)
      executeVote()
      setEmailModalVisible(false)
    }

    run().catch((e) => console.error('failed onSubmitEmailModal', e))
  }

  return (
    <div className={`vote ${className}`}>
      <div aria-describedby={id}>
        <Button className={`${btnClass} ${hasVote ? '' : 'success'}`} onClick={hasVote ? doVote : handleAlreadyVotedOnClick} label={label || hasVote ? 'Vote' : voteTally} />
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <Typography sx={{ p: 2 }}>You've already voted today.<br />Check back tomorrow to vote again!<br />This profile has {voteTally} votes.</Typography>
      </Popover>
      <EmailAndTermsModal defaultEmail={whoami?.user.email} visible={emailModalVisible} onCancel={onCancelEmailModal} onSubmit={onSubmitEmailModal} />
    </div>
  )
}

export default function VoteWidget({whoami, profile}: {whoami?: whoamiResponseType, profile: string}) {
  const [hasVote, setHasVote] = useState(true)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [voteTally, setVoteTally] = useState<string>('...');
  const [expanded, setExpanded] = useState(false);
  const [manualValidation, setManualValidation] = useState(false)
  const [emailModalVisible, setEmailModalVisible] = useState(false)
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const isAuthenticated = whoami?.user.uuid;

  useEffect(() => {
    getAvailableVotes().then((c) => {
      const b = c > 0
      setHasVote(b)
    })
  }, [setHasVote])

  useEffect(() => {
    vote.getTally(profile).then((r) => {
      setVoteTally(r.toString())
    })
  }, [profile, setVoteTally])

  function expandedToggle() {
    if (hasVote && !expanded) return;
    setExpanded(!expanded)
  }

  function handleAlreadyVotedOnClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null);
  }

  function executeVote() {
    voteForProfile(profile)
      .then(() => {
        setHasVote(false)
        setExpanded(true)
        return vote.getTally(profile)
      })
      .then((tally) => {
        setVoteTally(tally.toString())
      })
      .catch((e) => {
        console.error(e)
        alert('Something went wrong during voting. Please refresh and try again.')
      });
  }

  function doVote() {
    if (!isAuthenticated) {
      console.log('User is missing');
      const url = encodeURIComponent(window.location.pathname);
      const page = 'Vote'
      window.location.href = `/auth?url=${url}&page=${page}`;
      return;
    }
    if (!manualValidation && (!whoami.user.email || !whoami.profile.events?.includes(Event.BATTLE_OF_THE_BANDS_2023))) {
      setEmailModalVisible(true)
      return
    }
    executeVote();
  }

  function onCancelEmailModal() {
    setEmailModalVisible(false)
  }

  function onSubmitEmailModal(email: string) {
    async function run() {
      // Update email in user profile
      if (whoami?.user.email !== email) {
        await updateUser({uuid: whoami?.user.uuid!, email})
      }
      // Subscribe to event (if applicable)
      if (!whoami?.profile.events?.includes(Event.BATTLE_OF_THE_BANDS_2023)) {
        await commProfile.event.register(Event.BATTLE_OF_THE_BANDS_2023, {
          type: BattleOfTheBandsEventType.FAN,
        })
      }
      setManualValidation(true)
      executeVote()
      setEmailModalVisible(false)
    }

    run().catch((e) => console.error('failed onSubmitEmailModal', e))
  }

  return (
    <div className='dj3n-vote-widget'>
      <div className={`container ${expanded ? 'show' : ''}`}>
        <div className='primary'>
          <div className='cover' style={{backgroundImage: `url("${LHLogoSmall}")`}}></div>
          <div className='description'>
            <p className={`header ${hasVote ? '' : 'click'}`} onClick={expandedToggle}>Battle of the Bands Contestant</p>
            <p className='sub'>Vote for us to open up for Lynyrd Skynyrd at Poconos Park, May 19 in Bushkill, PA</p>
          </div>
          <div className='vote'>
            <div aria-describedby={id}>
              <Button className={`${hasVote ? '' : 'success'}`} onClick={hasVote ? doVote : handleAlreadyVotedOnClick} label={hasVote ? 'Vote Now' : voteTally} />
            </div>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
            >
                <Typography sx={{ p: 2 }}>You've already voted today.<br />Check back tomorrow to vote again!<br />This profile has {voteTally} votes.</Typography>
            </Popover>
          </div>
        </div>
        <div className='expanded'>
          <a className='no-link' href={routes.external.botb.buyTickets} target='_blank'>
            <img src={VotedImage} alt="Thanks for voting in the Battle of the Bands but don't miss out on the main event! Grab your tickets to see your favorite band potentially open LIVE for Lynyrd Skynard, Marshall Tucker and more... The concert and motorcycle show is just around the corner, hope to see you there! Click to buy tickets." />
          </a>
        </div>
      </div>
      <EmailAndTermsModal defaultEmail={whoami?.user.email} visible={emailModalVisible} onCancel={onCancelEmailModal} onSubmit={onSubmitEmailModal} />
    </div>
  )
}

export function ReVoteWidget({user}: {user: string}) {
  const [isLoading, setIsLoading] = useState(true)
  const [hasVote, setHasVote] = useState(false)
  const [profileData, setProfileData] = useState<Profile>()
  const [voteProfile, setVoteProfile] = useState<string>()

  useEffect(() => {
    vote.getHistory()
      .then((vs) => {
        if (!vs || !vs.length) return
        setVoteProfile(vs[0].recipient)
      })
      .catch((e) => console.error('ReVoteWidget', e))
  }, [user, setVoteProfile])

  useEffect(() => {
    if (!voteProfile) return
    getProfileByUuid(voteProfile).then((p) => {
      setProfileData(p)
      setIsLoading(false)
    }).catch((e) => {
      console.error('ReVoteWidget', e)
    })
  }, [setProfileData, voteProfile])

  useEffect(() => {
    getAvailableVotes().then((c) => {
      console.log('availableVotes', c)
      const b = c > 0
      console.log('hasVote', b)
      setHasVote(b)
    })
  }, [setHasVote])

  function doVote() {
    if (!voteProfile) return
    if (!user) {
      console.log('User is missing');
      const url = encodeURIComponent(window.location.pathname);
      const page = 'Vote'
      window.location.href = `/auth?url=${url}&page=${page}`;
      return;
    }
    voteForProfile(voteProfile)
      .then(() => setHasVote(false))
      .catch((e) => {
        console.error(e)
        alert('Something went wrong during voting. Please refresh and try again.')
      });
  }

  if (isLoading) {
    return <></>
  }

  return (
    <>
      <div className='dj3n-vote-widget recent'>
        <div className='container'>
          <div className='primary'>
            <Avatar className='avatar' src={profileData?.profileImage} />
            <div className='description'>
              <p className='header'>Recent vote</p>
              <p className='handle'><a className='no-link' href={`/nfts/gallery/${profileData?.uuid}`}>{profileData?.name || `3/${profileData?.handle}`}</a></p>
            </div>
            <div className='vote'>
              <Button disabled={!hasVote} onClick={doVote} label={hasVote ? 'Vote Again' : 'Voted'} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
