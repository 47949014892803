import {debounce, DebounceSettings} from 'lodash'
import {useRef} from 'react'
import Profile, {BattleOfTheBandsEventType, Event} from "../types/profile.types";

/**
 * Adds '//' if the given url doesn't starts with href.
 * @param url {string} the url
 * @returns {string} The url starting with '//'
 */
export const checkHref = (url: string): string => {
  if (url.indexOf("//") > 0) {
    return url;
  }
  return "//" + url;
};

export const truncateString = (input: string, num = 12) => {
  if (input.length > num) {
    return input.substring(0, num) + "...";
  }
  return input;
};

export const truncateAddressString = (address: string, num = 12) => {
  if (!address) {
    return "";
  }
  const first = address.slice(0, num);
  const last = address.slice(-num);
  return `${first}...${last}`;
};

export const b64toBlob = (dataURI: any) => {
  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
};

interface DebouncedArgs<T> {
  delay?: number
  callback?: (value: T) => void
  debounceSettings?: DebounceSettings
}

export const useDebounce = <T = unknown>({ callback, debounceSettings, delay = 700 }: DebouncedArgs<T>) => {
  const dispatchValue = (value: T) => callback?.(value)

  const setValueDebounced = useRef(debounce(dispatchValue, delay, { ...debounceSettings, maxWait: debounceSettings?.maxWait || 1400 }))

  return (value: T) => setValueDebounced.current(value)
}

export function truncateUrl(s: string, n: number = 12, opts?: {suffix: string}) {
  const noHref = s.replace(/http.*:\/\//g, '')
  let ans = noHref.slice(0, n)
  if (opts?.suffix && ans.length < noHref.length) ans += opts.suffix
  return ans
}

const EMAIL_REGEX = /\S+@\S+\.\S+/;

export function validateEmail(s: string) {
  const re = EMAIL_REGEX;
  return re.test(s);
}

export function profileIsBotbCreator(p: Profile) {
  return p && p.eventRegistrations && p.eventRegistrations[Event.BATTLE_OF_THE_BANDS_2023]
    && p.eventRegistrations[Event.BATTLE_OF_THE_BANDS_2023]?.type === BattleOfTheBandsEventType.CREATOR
}
