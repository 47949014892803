import _ from 'lodash'
import Frame from "../../components/frame";
import TextField from "../../components/mui/text-field";
import React, {ChangeEvent, useEffect, useState} from "react";
import Button from "../../components/button/button.component";
import {socialLink, whoami} from "../../utils/communicator";
import Select from '../../components/mui/select';
import linkTypes, {Provider, Provider as LinkProvider} from "../../utils/linkTypes";
import {SingleValue} from "react-select";
import {SocialType, whoamiResponseType} from "../../types";
import {useParams} from "react-router-dom";

import './LinkManager.styles.scss';
import {DeleteOutline, DragIndicator} from "@mui/icons-material";
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';
import LoadingFrame from "../../components/loading-frame";

function _getLinkTypeOptions() {
  const types = linkTypes;
  const ans: {value: string, label: string}[] = [];
  _.forOwn(types, (v, k) => {
    ans.push({value: k, label: v.label});
  });
  return ans;
}

const linkTypeOptions = _getLinkTypeOptions()

function LinkRow({label, id, index, onDelete}: {label: string, id: string, index: number, onDelete: (uuid: string) => any}) {
  function deleteLink() {
    async function doDeleteLink(uuid: string) {
      await socialLink.removeLink(uuid)
      onDelete(uuid)
    }
    doDeleteLink(id).catch((e) => console.error('deleteLink', e))
  }

  return (
    <Draggable index={index} draggableId={id}>
      {provided => (
        <div
          className='dj3n-link-row'
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps} >
          <DragIndicator />
          <span className='label'>{label}</span>
          <DeleteOutline className='click' onClick={deleteLink} />
        </div>
      )}
    </Draggable>
  )
}

const LinkList = function LinkList({ links, onDelete }: {links: SocialType[], onDelete: (uuid: string) => any}) {
  function truncateUrl(s: string) {
    return s.replace(/http.*:\/\//g, '').slice(0, 12)
  }

  const LinkElements = links.map((l: SocialType, index: number) => (
    <LinkRow index={index} id={l.uuid} key={l.uuid} label={l.type === Provider.CUSTOM ? truncateUrl(l.href) : linkTypes[l.type].label} onDelete={onDelete} />
  ));
  return (
    <div>
      {LinkElements}
    </div>
  )
}

function LinkOrderControl({profile}: {profile: string}) {
  const [links, setLinks] = useState<SocialType[]>([])

  useEffect(() => {
    async function doGetLinks() {
      const ls = await socialLink.getLinksByProfile(profile)
      setLinks(ls)
      return ls
    }

    doGetLinks()
      .then((ls) => console.log('Found links', ls))
      .catch((e) => console.error('Failed to get existing links', e))
  }, [setLinks])

  function saveRanking() {
    async function doSubmitRank(ls: SocialType[]) {
      const uuids = ls.map((x) => x.uuid);
      await socialLink.submitRanking(uuids);
      window.location.href = `/nfts/gallery/${profile}?tab=social`
    }
    doSubmitRank(links)
      .catch((e) => console.error('saveRanking', e));
  }

  function onDragEnd({destination, source}: DropResult) {
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const ls = reorder(
      links,
      source.index,
      destination.index
    );

    setLinks(ls);
  }

  function onDelete(uuid: string) {
    const ls = [...links].filter((x) => x.uuid !== uuid)
    setLinks(ls)
  }

  function reorder(list: any[], startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  return (
    <div className='link-order-control'>
      <DragDropContext
        onDragEnd={onDragEnd}
      >
        <Droppable droppableId='droppable'>
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <LinkList links={links} onDelete={onDelete} />
              {provided.placeholder}
            </div>
            )}
        </Droppable>
      </DragDropContext>
      { !!links.length && <Button className='primary-action' label='Save' onClick={saveRanking} /> }
    </div>
  )
}

export default function LinkManager() {
  const [session, setSession] = useState<whoamiResponseType>()
  const [linkType, setLinkType] = useState<LinkProvider>()
  const [linkHref, setLinkHref] = useState('')
  const [linkImage, setLinkImage] = useState('')

  useEffect(() => {
    whoami()
      .then((s) => setSession(s))
      .catch((e) => console.error('failed to get session', e))
  }, [setSession])

  useEffect(() => {
    if (!linkHref) return;
    let determinedType = Provider.CUSTOM
    Object.entries(linkTypes).forEach(([provider, link]) => {
      let isPresent = false
      link.urls.forEach((url) => {
        isPresent = linkHref.toLowerCase().includes(url) || isPresent
      })
      if (isPresent) {
        determinedType = provider as Provider
        return false
      }
    })
    setLinkType(determinedType)
  }, [linkHref, setLinkType])

  function onChangeHref(evt: ChangeEvent<HTMLInputElement>) {
    const v = evt.target.value
    setLinkHref(v)
  }

  function onChangeLinkImage(evt: ChangeEvent<HTMLInputElement>) {
    const v = evt.target.value;
    setLinkImage(v)
  }

  function sanitizeHref(s: string) {
    if (!s.startsWith('http')) {
      return `https://${s}`
    }
    return s
  }

  function submit() {
    async function doSubmit() {
      if (!linkType) {
        console.warn(`linkType was undefined while trying to submit link`)
        return
      }
      const sanitizedHref = sanitizeHref(linkHref)
      const l = {
        type: linkType,
        href: sanitizedHref,
        image: linkImage,
      };
      return await socialLink.addLink(l);
    }
    doSubmit()
      .then(() => window.location.reload())
      .catch((e) => console.error('Failed to add link', e));
  }

  function filterOptions(candidate: { label: string; value: string; data: any }, input: string) {
    if (input) {
      return candidate.value === LinkProvider.CUSTOM || candidate.label.toLowerCase().includes(input.toLowerCase());
    }
    return true;
  }

  if (!session) {
    return <LoadingFrame />
  }

  return (
    <Frame className='link-manager'>
      <div className='link-creation'>
        <TextField onChange={onChangeHref} value={linkHref} placeholder='Enter URL' />
        {linkType === LinkProvider.CUSTOM && <TextField onChange={onChangeLinkImage} value={linkImage} placeholder='Image reference' /> }
        <Button className='primary-action' label='Add Link' onClick={submit} />
      </div>
        <div>
          <LinkOrderControl profile={session.profile.uuid!} />
      </div>
    </Frame>
  )
}
