import Frame from "../../components/frame";
import './invite.styles.scss'
import TextField from "../../components/mui/text-field";
import {ChangeEvent, useEffect, useState} from "react";
import Button from "../../components/button/button.component";
import {invite} from "../../utils/communicator";
import routes from "../index";

export default function InviteCreation() {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsValid(!!(name && email && email.includes('@') && email.split('@')[1].includes('.')))
  }, [name, email, setIsValid])

  function changeName(evt: ChangeEvent<HTMLInputElement>) {
    setName(evt.target.value)
  }
  function changeEmail(evt: ChangeEvent<HTMLInputElement>) {
    setEmail(evt.target.value)
  }

  function submit() {
    invite.create(name, email)
      .then(() => window.location.href = routes.account)
      .catch((e) => console.error('create invite', e))
  }

  return (
    <Frame className='invite-creation'>
      <h2 className='header'>Who do you want to invite?</h2>
      <span className='hint'>Invited users can manage most aspects of the shared profile.<br/>After inviting, they'll receive an email with a special link they can use to join your profile.</span>
      <div className='input-wrapper'>
        <TextField label="First & Last Name" onChange={changeName} value={name} /><br/>
        <TextField label='Email' onChange={changeEmail} value={email} />
        <Button label='Invite' onClick={submit} disabled={!isValid} className='primary-action' />
      </div>
    </Frame>
  )
}
