import { Box } from "@mui/material";
import './horizontal-scroller.scss';
import {ReactNode} from "react";

export enum Variant {
  square = 0,
  circle = 1,
}

type ScrollObject = {
  image: string,
  author: string,
  link?: string,
}

type ScrollElementType = {
  image?: string,
  description?: string,
  width: number,
  height: number,
  link?: string,
  variant?: Variant,
};

export function ScrollElement({image, description, width, height, link, variant}: ScrollElementType) {
  function handleClick() {
    if (!link) return;
    window.location.href = link;
  }

  const variantClassName = variant === Variant.circle ? 'dj3n-horizontal-scroll-variant-circle' : 'dj3n-horizontal-scroll-variant-square'

  return (
    <div className={'dj3n-horizontal-scroll-element-outer'}>
      <Box className={`dj3n-horizontal-scroll-element ${variantClassName} ${link ? 'click' : ''}`} onClick={handleClick} style={{ width: width, height: height, backgroundImage: `url(${image})` }}></Box>
      {description && <span className='description' style={{ maxWidth: width }}><a className='no-link' href={link}>{description}</a></span>}
    </div>
  )
}

export function Section({data, label, width, height, variant, href} : {data: Array<any>, label: ReactNode, width: number, height: number, variant?: Variant, href?: string}) {
  return (
    <Box style={{ marginTop: 16}}>
      <h3 className='dj3n-section-header'><a className='no-link' href={href}>{label}</a></h3>
      <HorizontalScroller data={data} width={width} height={height} variant={variant} />
    </Box>
  )
}

export default function HorizontalScroller({data, variant, width, height}: {data: Array<ScrollObject>, variant?: Variant, width: number, height: number}) {
  return (
    <Box className='dj3n-horizontal-scroll-wrapper'>
      {data.map((e) => <ScrollElement {...e} variant={variant || Variant.square} width={width} height={height} />)}
    </Box>
  )
}
