import Frame from "../../components/frame";
import TextField from "../../components/mui/text-field";
import {ChangeEvent, useEffect, useState} from "react";
import Button from "../../components/button/button.component";

import './invite.styles.scss'
import {useParams} from "react-router-dom";
import {useDebounce} from "../../utils/utils";
import {invite, searchProfiles, whoami} from "../../utils/communicator";
import {whoamiResponseType} from "../../types";
import {smsLoginHandler} from "../../utils/sms-wallet/sms-wallet.utils";

type DebouncedQueryArgs = {v: string; fn: (v: boolean) => void}

export default function Invite({}) {
  const [session, setSession] = useState<whoamiResponseType>()
  const [claimCode, setClaimCode] = useState('')
  const [isValidClaim, setIsValidClaim] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const { claim: claimCodeFromUrl } = useParams()
  const executeDebouncedClaimLookup = useDebounce<DebouncedQueryArgs>({callback: executeClaimCodeQuery})

  useEffect(() => {
    async function run() {
      try {
        const session = await whoami()
        setSession(session)
      } catch (e) {
        await smsLoginHandler('', 'Claim Invite', window.location.pathname);
        return
      }
      if (!claimCodeFromUrl) return
      setClaimCode(claimCodeFromUrl)
    }
    run().catch((e) => console.error('init useEffect', e))
  }, [setSession, whoami, setClaimCode, smsLoginHandler])

  useEffect(() => {
    if (!claimCode) {
      setErrorMsg('')
      return
    }
    executeDebouncedClaimLookup({v: claimCode, fn: handleClaimCodeResult})
  }, [claimCode, handleClaimCodeResult])

  function onChangeClaimCode(e: ChangeEvent<HTMLInputElement>) {
    const v = e.target.value
    setClaimCode(v)
  }

  function executeClaimCodeQuery({v, fn}: DebouncedQueryArgs) {
    async function bouncy() {
      try {
        const isValid = await invite.get(v)
        fn(isValid)
      } catch (e) {
        fn(false)
      }
    }
    bouncy().catch((e) => console.error('claimCodeQuery', e))
  }

  function handleClaimCodeResult(isValid: boolean) {
    setIsValidClaim(isValid)
    if (!isValid) {
      setErrorMsg('Invalid code')
    }
  }

  function submit() {
    invite.claim(claimCode)
      .then(() => {
        alert('Claim successful, switch profiles using the portrait in the top right.')
        window.location.reload()
      })
      .catch((e) => {
        console.error(`Failed to claim with code ${claimCode}`, e)
      })
  }

  return (
    <Frame>
      <div className='claim'>
        <TextField className='claim-input' onChange={onChangeClaimCode} value={claimCode} label='Claim Code' error={!!errorMsg} helperText={errorMsg} />
      </div>
      <Button className='primary-action' label='Accept Invite' onClick={submit} disabled={!isValidClaim} />
    </Frame>
  )
}