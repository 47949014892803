import React, {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from "react";

import "./user-profile.styles.scss";
import { NavigateFunction, useNavigate } from "react-router-dom";
import SendIcon from '@mui/icons-material/Send';
import {Styles} from "@fortawesome/fontawesome-svg-core";
import {RWebShare} from "react-web-share";

export type EditorProps = {
  updateProfile: (
    name: string,
    handle: string,
    profileDescription: string
  ) => void;
  updateImage: (profileImage: FileList | null) => void;
  updateBackground: (profileImage?: FileList | null) => void;
};

export type UserProfileProps = {
  name?: string;
  handle?: string;
  profileImage?: any;
  profileImageBg?: any;
  description?: string;
  editable?: boolean;
  editor?: EditorProps;
  children?: ReactNode;
};

type EditableComponentProps = {
  value: string;
  stateAction: Dispatch<SetStateAction<string>>; //Dispatch<SetStateAction<string | undefined>>;
  editing: boolean;
  editingCls: string;
  normalCls: string;
  placeholder: string;
  textArea?: boolean;
  isLink?: boolean;
  navigator?: NavigateFunction;
};

export function ProfileBanner(props: { profileImage?: any, editable: undefined | boolean, onChangePortrait: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined, profileImageBg?: any, onChangeBanner: (e: React.ChangeEvent<HTMLInputElement>) => void | undefined, backgroundSize?: string }) {
  const avatarRef = useRef<HTMLHeadingElement>(null);

  const avatarStyle = {
    backgroundImage: '',
  };
  if (props.profileImage && props.profileImage.length) {
    avatarStyle.backgroundImage = `url(${props.profileImage})`
  }

  const bannerStyle: Styles = {}
  if (props.profileImageBg !== undefined && props.profileImageBg?.length !== 0) {
    bannerStyle.backgroundImage = `url(${props.profileImageBg})`
  }
  if (props.backgroundSize) {
    bannerStyle.backgroundSize = props.backgroundSize
  }

  return <div className="user-container__profile-images">
    <div className="user-container__profile-images--left">
      <div className='user-container__profile-images--body-logo' />
    </div>
    <div
      className={
        props.profileImage === undefined || props.profileImage?.length === 0
          ? `user-container__profile-images--avatar user-container__profile-images--avatar-default`
          : `user-container__profile-images--avatar`
      }
      style={avatarStyle}
      ref={avatarRef}
    >
      <input
        type="file"
        style={{
          opacity: 0,
          fontSize: "70px",
        }}
        accept="image/*"
        disabled={!props.editable}
        onChange={props.onChangePortrait}
      />
    </div>
    <div
      className={
        props.profileImageBg === undefined || props.profileImageBg?.length === 0
          ? `user-container__profile-images--right user-container__profile-images--right-default`
          : `user-container__profile-images--right user-container__profile-images--right-custom`
      }
      style={bannerStyle}
    >
      <input
        type="file"
        style={{opacity: 0, fontSize: "150px", marginLeft: "20%"}}
        disabled={!props.editable}
        accept="image/*,video/*"
        onChange={props.onChangeBanner}
      />
    </div>

  </div>;
}

export const UserProfile = (props: UserProfileProps) => {
  const {
    name,
    handle: _handle,
    profileImage,
    profileImageBg,
    description,
    editable,
    editor,
    children,
  } = props;

  const handle = _handle || 'username'

  const [nameStr, setName] = useState('');
  const [profileDescription, setDescription] = useState('');
  const [link, setLink] = useState('');

  useEffect(() => {
    name && setName(name);
    handle && setLink(handle);
    if (description) setDescription(description);
    else if (!description && editable) setDescription('This is your DJ3N profile. You can edit your profile by clicking the avatar in the top right corner. Profiles that upload a photo are eligible to be featured in the discover page!')
  }, [name, handle, description]);

  const handleNode = (handle && editable && <div className="user-container__info--link"><a className='no-link' href='/profile/edit'>{`3/${link}`}</a></div>)
    || (handle && <div className="user-container__info--link">{`3/${link}`}</div>)
    || null
  
  return (
    <div className="user-container">
      <ProfileBanner profileImage={profileImage} editable={editable}
                     onChangePortrait={(e) => {
                       const target = e.target as HTMLInputElement;
                       const files = target.files as FileList;
                       return props.editor?.updateImage(files);
                     }}
                     profileImageBg={profileImageBg}
                     onChangeBanner={(e) => {
                       const target = e.target as HTMLInputElement;
                       const files = target.files as FileList;
                       return props.editor?.updateBackground(files);
                     }}/>
      {children}
      <div className="user-container__info">
        <div className='title'>
          <span
            className="name"
            placeholder="">
            {nameStr}
          </span>
          <div className='action-send'>
            <RWebShare
              data={{
                text: `${name}'s biggest fans are using this platform to connect. Check out what you're missing.`,
                url: window.location.href,
                title: "Share profile",
              }}
              onClick={() => console.log("shared successfully!")}
            >
              <SendIcon className='icon-inline click' />
            </RWebShare>
          </div>
        </div>
          {handleNode}
          <div className="user-container__info--description">{profileDescription}</div>
      </div>
    </div>
  );
};
