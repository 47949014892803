import {useSelector} from "react-redux";
import {selectCurrentUser} from "../../store/user/user.selector";
import {Navigate} from "react-router-dom";
import {useEffect} from "react";
import {whoami} from "../../utils/communicator";
import Frame from "../../components/frame";
import CircularProgressWithLabel from "../../components/progress-with-label/progress-with-label.component";
import LoadingFrame from "../../components/loading-frame";

export default function ProfileRedirect() {
  useEffect(() => {
    whoami().then( (u) => {
      window.location.replace(`/nfts/gallery/${u?.profile?.uuid}`);
    }).catch((e) => {
      if (e?.response?.status === 403) {
        return window.location.href = '/auth';
      }
      console.error('whoami failed', e);
    });
  }, []);

  return (
    <LoadingFrame />
  )
}