import { useEffect } from "react";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";

import Landing from "./routes/landing/landing.component";
import Login from "./routes/auth/login.component";
import SmsLoginVerify from "./routes/sms-login-verify/sms-login-verify.component";
import { saveSessionState } from "./utils/storage/session-storage.utils";
import { saveLocalState } from "./utils/storage/local-storage.utils";

import { store } from "./store/store";

import "./App.scss";
import ProfileEditor from "./routes/profile-editor/profile-editor.component";
import ProfileRedirect from "./routes/redirect/ProfileRedirect";
import VoteSharePage from "./routes/vote-share-page/VoteSharePage";
import BattleOfTheBandsSignup from "./routes/botb/botb-artist";
import LinkManager from "./routes/link-manager/LinkManager";
import Invite from "./routes/invite/invite.component";
import routes from './routes'
import InviteCreation from "./routes/invite/invite-create.component";
import Browse from "./routes/browse/browse.component";
import {getRandomProfiles, getRecentProfiles, vote} from "./utils/communicator";
import Logout from './routes/auth/logout.component';
import BattleOfTheBandsLanding from "./routes/botb/botb-landing";
const Wallet = lazy(() => import("./routes/wallet/wallet.component"));

const CreateHome = lazy(
  () => import("./routes/create-home/create-home.component")
);
const CreateCollectible = lazy(
  () => import("./routes/create-collectible/create-collectible.component")
);
const FiatOnramp = lazy(
  () => import("./routes/fiat-onramp/fiat-onramp.component")
);

const CreateAccessPass = lazy(() => import("./routes/create-access-pass"));
const Gallery = lazy(() => import("./routes/gallery/gallery.component"));
// const Navigation = lazy(
//   () => import("./routes/navigation/navigation.component")
// );

const CheckoutSuccess = lazy(
  () => import("./routes/checkout/checkout-success.component")
);
const CheckoutFailure = lazy(
  () => import("./routes/checkout/checkout-failure.component")
);
const Vanity = lazy(() => import("./routes/vanity/vanity.component"));
// const ClaimNft = lazy(() => import("./routes/claim-nft/claim-nft.component"));
// const AccessPassDetails = lazy(() => import("./routes/access-pass-details/access-pass-details.component"));
const TokenDetail = lazy(
  () => import("./routes/token-detail/token-detail.component")
);

const App = () => {
  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      const user = store.getState().user;
      if (user && user.currentUser) {
        saveSessionState(store.getState());
        saveLocalState(user.currentUser.phone);
      }
    });

    return unsubscribe;
  }, []);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="auth/" element={<Login />} />
          <Route path="verify/" element={<SmsLoginVerify />} />
          <Route path="onramp" element={<FiatOnramp />} />

          {/* Private routes */}
          <Route path="nfts/">
            <Route index element={<CreateHome />} />
            <Route
              path="create-collectible/:redirect"
              element={<CreateCollectible />}
            />
            <Route path="create-collectible/" element={<CreateCollectible />} />
            <Route
              path="create-access-pass/:redirect"
              element={<CreateAccessPass />}
            />
            <Route path="create-access-pass/" element={<CreateAccessPass />} />
            <Route path="gallery/:ownerUuid" element={<Gallery />} />
            <Route path="token/:uuid/" element={<TokenDetail />} />
            <Route
              path="success/:userUuid/:checkoutSession"
              element={<CheckoutSuccess />}
            />
          </Route>
          <Route path={routes.profile.editLinks} element={<LinkManager />} />

          {/* User get starting routes */}
          <Route path="started/">
            <Route index element={<CreateHome />} />
            <Route path="create-collectible/" element={<CreateCollectible />} />
            <Route path="create-access-pass/" element={<CreateAccessPass />} />
          </Route>

          <Route path={routes.botb.index} element={<BattleOfTheBandsLanding />} />
          <Route path={routes.botb.signupWithExisting} element={<BattleOfTheBandsSignup useExisting />} />
          <Route path={routes.botb.signup} element={<BattleOfTheBandsSignup />} />
          <Route path={routes.account} element={<Wallet />} />

          {/* Public routes */}
          <Route path="token/:uuid/" element={<TokenDetail />} />
          <Route
            path="success/:userUuid/:tokenUuid"
            element={<CheckoutSuccess />}
          />
          <Route path="cancel/:userUuid" element={<CheckoutFailure />} />
          <Route path="u/:vanityUrl" element={<Vanity />} />
          <Route path="3/:vanityUrl" element={<Vanity />} />
          <Route path="gallery/:ownerUuid" element={<Gallery />} />
          <Route path={routes.browse.profile.vote.byUuid(':uuid')} element={<VoteSharePage />} />
          <Route path={routes.browse.profile.vote.byHandle(':handle')} element={<VoteSharePage />} />
          <Route path={routes.profile.vote} element={<VoteSharePage />} />
          <Route path={routes.profile.edit} element={<ProfileEditor />} />
          <Route path={routes.profile.view} element={<ProfileRedirect />} />
          <Route path={routes.invite.create} element={<InviteCreation />} />
          <Route path={routes.invite.claim(':claim')} element={<Invite />} />
          <Route path={routes.browse.trending} element={<Browse title='Trending Artists' queryFn={async () => {
            const vd = await vote.getTrending()
            return vd.map((x) => x.profile)
          }} />} />
          <Route path={routes.browse.discover} element={<Browse title='Discover' queryFn={async () => getRandomProfiles(100)} />} />
          <Route path={routes.browse.botb.discover} element={<Browse title='Discover' queryFn={async () => getRandomProfiles(100, true)} />} />
          <Route path={routes.browse.botb.latest} element={<Browse title='Latest Artists' queryFn={async () => getRecentProfiles(100, true)} />} />
          <Route path={routes.logout} element={<Logout />} />
        </Routes>
      </Suspense>
    </>
  );
};
//hmy/
export default App;
