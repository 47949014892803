import CircularProgress from "@mui/material/CircularProgress";
import * as React from "react";
import Frame from "../frame";

import './dj3n-loading-frame.scss';
import {Box} from "@mui/material";

export default function LoadingFrame({}) {
  return (
    <Frame>
      <Box className='dj3n-loading-frame'>
        <CircularProgress variant="indeterminate" color="inherit" size="100px"/>
      </Box>
    </Frame>
  );
}