import React, {ReactNode} from 'react'

import './modal.styles.scss'

export default function Modal({children, visible, onCancel, className}: {children: ReactNode, visible: boolean, onCancel: () => any, className?: string}) {
  if (!visible) {
    return <></>
  }
  return (
    <div className={`modal ${className || ''}`}>
      <div className='modal-shadow' onClick={onCancel} />
      <div className='modal-wrap'>
        <div className='content'>
          {children}
        </div>
      </div>
    </div>
  )
}
