/* eslint-disable jsx-a11y/alt-text */
// @ts-nocheck
import { useEffect } from "react";
import { useDropzone } from "react-dropzone";

import "./dropzone.styles.scss";

type LockedContentDropzoneProps = {
  files: Array<any>;
  setFiles: any;
};

const LockedContentDropzone = ({
  files,
  setFiles,
}: LockedContentDropzoneProps) => {
  console.log('lockecContent',files);
  const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  };

  const thumb = {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  };

  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  };

  const img = {
    display: "block",
    width: "auto",
    height: "100%",
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
      "video/*": [],
      "audio/*": [],
    },
    onDrop: (acceptedFiles) => {
      // @ts-ignore
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  const thumbs = files.map((file) => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview);
          }}
        />
      </div>
    </div>
  ));

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  return (
    <section className="dg3n-locked-content-dropzone">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} multiple={true} />
        <p>
          Drag 'n' drop exclusive content available to future owners of this
          collectible
        </p>
      </div>
      <aside style={thumbsContainer}>{thumbs}</aside>
    </section>
  );
};

export function SingleImageDropzone({
  files,
  setFiles,
}: LockedContentDropzoneProps) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => {
      // @ts-ignore
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  }, []);

  let previewImageStyle = {};
  if (files.length) {
    previewImageStyle = {
      backgroundImage: `url(${files[0].preview})`,
      backgroundSize: "cover",
    };
  }

  const tooltip = files.length ? "" : "Let's create! Drop an image to begin.";

  return (
    <section className="dg3n-locked-content-dropzone">
      <div
        {...getRootProps({
          className: "dropzone dj3n-full-preview",
          style: previewImageStyle,
        })}
      >
        <input {...getInputProps()} multiple={true} />
        <p>{tooltip}</p>
      </div>
    </section>
  );
}

export function SingleAudioDropzone({file, setFile}: {file?: File | null, setFile: (f: File) => any}) {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "audio/*": [],
    },
    onDrop: (acceptedFiles) => setFile(acceptedFiles[0])
  });

  const tooltip = file ? file.name : "Drop an audio file here to feature it on your profile.";

  return (
    <section className="dg3n-locked-content-dropzone">
      <div
        {...getRootProps({
          className: "dropzone",
        })}
      >
        <input {...getInputProps()} />
        <p>{tooltip}</p>
      </div>
    </section>
  );
}

export default LockedContentDropzone;
