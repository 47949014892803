import {useEffect} from "react";
import LoadingFrame from "../../components/loading-frame";
import {logout} from "../../utils/communicator";
import routes from "../index";

export default function Logout() {
  useEffect(() => {
    logout()
      .then(() => {
        window.location.href = routes.home;
      }).catch((e) => {
        console.error('Failed to logout', e)
      })
  }, [])

  return <LoadingFrame />
}
