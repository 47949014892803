import {ReactNode} from "react";
import Header from "../header/header.component";

import './frame.scss';
import {FabEmpty} from "../mui/fab";

export default function Frame({fab, className, disableMenu, action, children}: {fab?: ReactNode, className?: string, disableMenu?: boolean, action?: ReactNode, children: ReactNode}) {
  return (
    <>
      <Header disableMenu={disableMenu}>{action}</Header>
      {!!fab ? fab : <FabEmpty />}
      <div className={`dj3n-content-frame ${className ? className : ''}`}>
        {children}
      </div>
    </>
  )
}