import { CSSProperties } from 'react';
import { MouseEventHandler } from 'react';

import './button.styles.scss';

type ButtonType = {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  href?: string;
}

const Button = (props : ButtonType) => {
  const { label, className, style, href, onClick: providedOnClick, ...otherProps } = props;

  // @ts-ignore
  function onClick(...args) {
    if (href) window.location.href = href;
    if (providedOnClick) { // @ts-ignore
      providedOnClick(...args)
    }
  }

  let cl = ''
  if (className) {
    if (className === 'info') {
      cl = 'button-container__info'
    } else {
      cl = className
    }
  }
  return (
    <div className='button-container' style={style ? style : undefined}>
      <button className={cl}
        type='submit' onClick={onClick}
        {...otherProps}>
        <span>{label}</span>
      </button>
    </div>
   
  )
}

export function CancelButton(props: ButtonType) {
  return <Button {...props} className={`primary-action btn-cancel ${props.className || ''}`} />
}

export default Button;