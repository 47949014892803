import axios, {AxiosResponse} from 'axios'
import UserTypeOptional from "../types/user.types";
import {
  ApiResponseType,
  CollectionType,
  Reservation, SocialRank,
  SocialType,
  StripeSession,
  TokenTypeEnum,
  whoamiResponseType
} from "../types";
import ProfileTypes, {BattleOfTheBandsEvent} from "../types/profile.types";
import {Provider} from "./linkTypes";

const privilegedAxios = axios.create({withCredentials: true, headers: {"content-type": "application/json"}})
const GATEWAY = global.REACT_APP_SMS_WALLET_GATEWAY;
const API_URI = GATEWAY;

export async function initLogin({phone, redirect} : {phone: string, redirect?: string}) {
  const r = await axios.post(`${API_URI}/v1/login/init`, {phone, redirect})
  if (r.status !== 200) {
    throw new CommunicatorError('ERR_UNKNOWN', JSON.stringify(r.data))
  }
  return r.data
}

export async function verifyLogin({signature, messageHash, address, error, cancelled}: {signature: string, messageHash: string, address: string, error?: string, cancelled: boolean, phone: string}) {
  const data = {signature, messageHash, address, error, cancelled}
  try {
    const r = await privilegedAxios.post(`${API_URI}/v1/login/verify`, data);
    return r.data
  } catch(e: any) {
    if (e.status === 403) {
      return false
    } else if (e.status !== 200) {
      throw new CommunicatorError(
        'ERR_UNKNOWN',
        `Received invalid status code, ${e.status} while trying to verify login`
      )
    }
  }  
}

export async function logout() {
  const r = await privilegedAxios.get(`${API_URI}/v1/login/logout`)
  return r.status === 200
}

export async function whoami(): Promise<whoamiResponseType> {
  const r = await privilegedAxios.get(`${API_URI}/v0/users/whoami?t=${new Date().getUTCSeconds()}`)
  return r.data
}

export async function getBalance() {
  const r = await privilegedAxios.get(`${API_URI}/v1/login/balance`)
  return r.data
}

export const updateUser = async (u: UserTypeOptional): Promise<AxiosResponse> => {
  const body = JSON.stringify(u);
  return await privilegedAxios.put(`${GATEWAY}/v0/users/`, body);
}

export async function createProfile(p?: ProfileTypes) {
  const body = JSON.stringify(p || {})
  return await privilegedAxios.post(`${GATEWAY}/profile/`, body)
}

export async function updateProfile(p: ProfileTypes) {
  const body = JSON.stringify(p)
  return await privilegedAxios.put(`${GATEWAY}/profile/`, body)
}

export async function getProfileByUuid(uuid: string): Promise<ProfileTypes> {
  const p = await privilegedAxios.get(`${GATEWAY}/profile/${uuid}`)
  return p.data
}

export const getEarningSummary = async () => {
  const res = await privilegedAxios.get(`${GATEWAY}/v0/users/earnings`)
  return res.data
}

export const requestPayout = async (): Promise<boolean> => {
  return (await privilegedAxios.get(`${GATEWAY}/v0/users/payout/request`)).status === 200
}

async function registerEvent(event: string, eventRegistration: BattleOfTheBandsEvent) {
  const res = await privilegedAxios.post(`${GATEWAY}/profile/event/${event}`, eventRegistration)
  return res.status === 200
}

export const profile = {
  create: createProfile,
  update: updateProfile,
  get: getProfileByUuid,
  getEarningSummary,
  requestPayout,
  event: {
    register: registerEvent,
  }
}

export const getRecentCollections = async (): Promise<Array<CollectionType>> => {
  const r = await axios.get(`${GATEWAY}/v0/collections/all`);
  return r.data;
}

export async function getRandomProfiles(n: number = 1, botbOnly?: boolean) {
  const suffix = botbOnly ? '/botb' : ''
  const r = await axios.get(`${GATEWAY}/profile/sample/${n}${suffix}`)
  return r.data
}

export async function searchProfiles(q: string) {
  const r = await axios.get(`${GATEWAY}/profile/search?q=${q}`)
  return r.data
}

export async function hideCollection(uuid: string, hide = true): Promise<boolean> {
  const r = await privilegedAxios.post(`${GATEWAY}/v0/collections/${uuid}/hide`, {hide})
  return r.status < 400
}

export const getReservation = async (reservationUuid: string): Promise<Reservation> => {
  const r = await privilegedAxios.get(`${GATEWAY}/reservation/${reservationUuid}`)
  return r.data
}

export const getCheckoutSession = async (sessionId: string): Promise<StripeSession> => {
  const r = await privilegedAxios.get(`${GATEWAY}/reservation/session/${sessionId}`)
  return r.data
}

export const getStripeAuthLink = async (): Promise<string> => {
  const { data } = await privilegedAxios.get(`${GATEWAY}/v0/stripe/get-oauth-link`);
  return data.url
};

export const getUserByUuid = async (
  userUuid: string
): Promise<UserTypeOptional | null> => {
  const { data } = await axios.get(`${GATEWAY}/v0/users/${userUuid}`);
  return data
};

export async function getCollectionsByOwnerAndType(ownerUuid: string, tokenType: TokenTypeEnum, hidden = false): Promise<CollectionType[]> {
  let path = `${GATEWAY}/v0/collections/${ownerUuid}/${tokenType}`
  if (hidden) path += '/hidden'
  const { data } = await axios.get(path)
  return data
};

export const createCollection = async (
  title: string,
  description: string,
  link: string,
  rate: number,
  supply: number,
  tokenType: TokenTypeEnum,
  perks: string = '',
  creatorRoyalty: number = 0,
  additionalDetails: string = '',
  collectionImages: string[] = [],
  properties: object = {},
  lockedContent: string[] = [],
): Promise<ApiResponseType | null> => {
  try {
    const URL = `${GATEWAY}/v1/collections/create`;
    const body = JSON.stringify({
      title,
      description,
      link,
      rate,
      maxMint: supply,
      tokenType,
      perks,
      additionalDetails,
      creatorRoyalty,
      collectionImages,
      properties,
      lockedContent,
    });

    const response = await privilegedAxios.post(URL, body);

    return {
      data: response.data,
      status: response.status,
    };
  } catch (e) {
    console.error(e)
    return null
  }
};

/**
 * Retrieves the Collection of a given Collection uid
 * @param collectionUuid {string} Collection uid
 * @returns Collection {CollectionType}
 */
export const getCollection = async (
  collectionUuid: string
): Promise<CollectionType> => {
  const { data } = await privilegedAxios.get(`${GATEWAY}/v0/collections/${collectionUuid}`);
  return data;
};

export const getUuidFromVanityTag = async (tag: string): Promise<string | null> => {
  try {
    const url = `${GATEWAY}/v0/users/vanity/${tag}`;
    const response = await axios.get(url);
    return response.data.uuid;
  } catch (e) {
    console.error(e);
    return null;
  }
}

export const checkoutCollectionV2 = async (
  collections: CollectionType[],

): Promise<AxiosResponse | null> => {

  //success/:tokenUuid/:tokenImage
  const BASE_URL = global.REACT_APP_BASE_URL;
  const REDIRECT_URL_SUCCESS = `${BASE_URL}/nfts/success/:userUuid/{CHECKOUT_SESSION_ID}`;
  const REDIRECT_URL_FAILURE = `${BASE_URL}/nfts/cancel/:userUuid`;
  console.log("checkout collectionv2");
  try {
    const body = JSON.stringify({
      nfts: collections.map((collection) => {
        return { collectionUuid: collection.uuid, quantity: 1 };
      }),
      successUrl: REDIRECT_URL_SUCCESS,
      cancelUrl: REDIRECT_URL_FAILURE,
    });
    console.log({body});
    console.log(JSON.stringify(body));
    const response = await privilegedAxios.post(`${GATEWAY}/v1/payment/checkoutv2`, body);
    console.log("RESPONSE", response);
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function listProfiles() {
  const url = `${GATEWAY}/profile/`;
  const response = await privilegedAxios.get(url);
  return response.data;
}

export async function selectProfile(uuid: string) {
  const url = `${GATEWAY}/profile/select/${uuid}`
  const response = await privilegedAxios.post(url)
  return response.status < 300
}

export async function getAvailableVotes() {
  const response = await privilegedAxios.get(`${GATEWAY}/vote/available`)
  return response.data.available
}

export async function voteForProfile(uuid: string) {
  if (!uuid) throw 'Invalid profile'
  const response = await privilegedAxios.post(`${GATEWAY}/vote`, {recipient: uuid})
  return response.data
}

async function getVoteHistory() {
  const response = await privilegedAxios.get(`${GATEWAY}/vote/history`)
  return response.data
}

type TrendingVoteData = {
  id: string
  profile: ProfileTypes
  score: number
}

async function getTrendingVotes(): Promise<TrendingVoteData[]>{
  const response = await axios.get(`${GATEWAY}/vote/trending`)
  return response.data
}

export async function getRecentProfiles(n: number = 10, botbOnly=false): Promise<ProfileTypes[]> {
  const suffix = botbOnly ? '/botb' : ''
  const response = await axios.get(`${GATEWAY}/profile/recent/${n}${suffix}`)
  return response.data
}

async function getVoteTally(profile: string) {
  const response = await axios.get(`${GATEWAY}/vote/tally/${profile}`)
  return response.data.votes
}

export const vote = {
  getAvailableVotes,
  voteForProfile,
  getHistory: getVoteHistory,
  getTrending: getTrendingVotes,
  getTally: getVoteTally,
}

async function getMyReactions(type: TokenTypeEnum, target: string) {
  const r = await privilegedAxios.get(`${GATEWAY}/reaction/${type}/${target}/me`)
  return r.data
}

async function getReactions(type: TokenTypeEnum, target: string) {
  const r = await axios.get(`${GATEWAY}/reaction/${type}/${target}`)
  return r.data
}

async function createReaction(type: TokenTypeEnum, target: string, emoji: string) {
  const d = {type, target, emoji}
  const r = await privilegedAxios.post(`${GATEWAY}/reaction`, d)
  return r.data
}

async function removeReaction(type: TokenTypeEnum, target: string, emoji: string) {
  const r = await privilegedAxios.delete(`${GATEWAY}/reaction/${type}/${target}/${emoji}`)
  return r.data
}

export const reaction = {
  getMyReactions,
  getReactions,
  createReaction,
  removeReaction,
}

export const token = {
  getMyClaims: async (collection: string) => {
    const response = await privilegedAxios.get(`${GATEWAY}/token/claims/${collection}/me`)
    return response.data
  }
}

export const socialLink = {
  getLinksByProfile: async (uuid: string) => {
    const response = await axios.get(`${GATEWAY}/profile/${uuid}/links`);
    return response.data;
  },
  addLink: async (l: Partial<SocialType> & {href: string, type: Provider}) => {
    const response = await privilegedAxios.post(`${GATEWAY}/profile/links`, l);
    return response.data;
  },
  submitRanking: async (listOfUuids: string[]) => {
    const response = await privilegedAxios.post(`${GATEWAY}/profile/links/priority`, {ranks: listOfUuids});
    return response.data;
  },
  removeLink: async (uuid: string) => {
    const response = await privilegedAxios.delete(`${GATEWAY}/profile/links/${uuid}`)
    return response.data
  }
}

export const collection = {
  hideCollection,
  createCollection,
  getCollection,
  getCollectionsByOwnerAndType,
  checkoutCollectionV2,
  getRecentCollections,
  nextSequence: async (uuid: string) => {
    const response = await axios.get(`${GATEWAY}/collection/${uuid}/next-sequence`)
    return response.data
  }
}

async function getInvite(uuid: string): Promise<boolean> {
  const response = await privilegedAxios.get(`${GATEWAY}/invite/profile/${uuid}`);
  return response.data.isAvailable as boolean
}

async function createInvite(name: string, email: string) {
  const response = await privilegedAxios.post(`${GATEWAY}/invite/profile`, {
    recipientName: name,
    recipientEmail: email,
  })
  return response.data
}

async function claimInvite(uuid: string) {
  const response = await privilegedAxios.post(`${GATEWAY}/invite/profile/accept`, {uuid})
  return response.data
}

export const invite = {
  get: getInvite,
  create: createInvite,
  claim: claimInvite,
}

class CommunicatorError {
  public name: string;
  public message: string;

  constructor(name: string, message: string) {
    this.name = name
    this.message = message
  }
}
