import './landing.styles.scss';
import Frame from '../../components/frame';
import {ScrollElement, Section} from "../../components/horizontal-scroller";
import {ChangeEvent, useEffect, useState} from "react";
import {
  getRandomProfiles,
  getRecentCollections,
  getRecentProfiles,
  searchProfiles,
  vote,
  whoami
} from "../../utils/communicator";

import FabCreate from "../../components/mui/fab";
import Profile from "../../types/profile.types";
import TextField from "../../components/mui/text-field";
import {useDebounce} from "../../utils/utils";
import {whoamiResponseType} from "../../types";
import BotbAd from "../../components/vote-widget/botb-ad.component";
import routes from "../index";

type DebouncedSearchArgs = {
  v: string,
  fn: (v: any) => void
}

const Landing = () => {
  const [session, setSession] = useState<whoamiResponseType>();
  const [recentProfiles, setRecentProfiles] = useState<Profile[]>([]);
  const [trendingVotes, setTrendingVotes] = useState<Profile[]>([]);
  const [tossupArtists, setTossupArtists] = useState<Profile[]>([]);
  const [searchQuery, setSearchQuery] = useState('')
  const [searchResults, setSearchResults] = useState<Profile[]>()
  const executeDebouncedSearch = useDebounce<DebouncedSearchArgs>({callback: executeSearchQuery})

  const scrollElementWidth = 169;
  const scrollElementHeight = 113;

  useEffect(() => {
    whoami().then((s) => setSession(s)).catch((e) => console.error('Failed to get session', e))
  }, [setSession])

  useEffect(() => {
    getRecentProfiles(10, true).then((result) => {
      console.log('Found recent collections', result);
      const lst = result.map(profileToScrollElementSerializer);
      setRecentProfiles(lst);
    });
  }, []);

  useEffect(() => {
    getRandomProfiles(10, true).then((result) => {
      console.log('Found recent collections', result);
      const lst = result.map(profileToScrollElementSerializer);
      setTossupArtists(lst);
    });
  }, [setTossupArtists]);

  useEffect(() => {
    async function doGetTrending(){
      const data = await vote.getTrending()
      const ls = data.map((x) => x.profile)
      console.log('profilesTrending', ls)
      const profiles = ls.map(profileToScrollElementSerializer)
      console.log('profileTrendingProfile', profiles)
      setTrendingVotes(profiles)
    }
    doGetTrending().catch((e) => console.error('getTrending', e))
  }, [setTrendingVotes])

  function profileToScrollElementSerializer(x: Profile) {
    return {
      image: x.profileImage,
      link: `/nfts/gallery/${x.uuid}`,
      description: x.name || `3/${x.handle}`,
    }
  }

  function onSearchChange(e: ChangeEvent<HTMLInputElement>) {
    const s = e.target.value
    setSearchQuery(s)
    executeDebouncedSearch({v: s, fn: setSearchResults})
  }

  function executeSearchQuery({v: q, fn}: DebouncedSearchArgs) {
    async function bouncy() {
      const results = await searchProfiles(q)
      fn(results)
    }
    bouncy().catch((e) => console.error('searchQuery', e))
  }

  function shuffle(arr: Array<any>) {
    return arr
      .map(value => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value)
  }

  return (
    <Frame className='dj3n-landing' fab={<FabCreate />}>
      <div className='content'>
        <div className='page-title'>
          <div className='title'>Discover</div>
          <a className={`no-link ${!!session?.profile ? '' : 'hide'}`} href={routes.profile.view}><div className='see-profile'>
            <p className='view-text'>View your profile</p>
            <p className='username'>3/{session?.profile.handle}</p>
          </div></a>
        </div>
        <Section data={trendingVotes} label="Trending Artists" href={routes.browse.trending} width={scrollElementWidth} height={scrollElementHeight} />
        <div className='dj3n-search-input'>
          <TextField label='🔍 Search' onChange={onSearchChange} value={searchQuery} />
        </div>
        <div className={`dj3n-search-results ${!searchQuery ? 'hide' : ''}`}>
          <h3 className='dj3n-section-header'>Search Results</h3>
          <div className={`results ${!searchResults?.length ? 'empty': ''}`}>
            { searchResults?.map((x) =>
              <ScrollElement
                image={x.profileImage}
                width={scrollElementWidth} height={scrollElementHeight}
                description={x.name || `3/${x.handle}`}
                link={`/nfts/gallery/${x.uuid}`}
                key={x.uuid}
              />
            )
            }
            {!searchResults?.length && <><p>No results found</p><p>Hint: Our search works best with with full words.<br/>For example: "Paul" rather than "Pa".</p></> }
          </div>
        </div>
        <div className={`dj3n-primary-info ${searchQuery ? 'hide' : ''}`}>
          <BotbAd />
          <Section data={recentProfiles} label="Latest Artists" width={scrollElementWidth} height={scrollElementHeight} href={routes.browse.botb.latest} />
          <Section data={tossupArtists} label='Discover' width={scrollElementWidth} height={scrollElementHeight} href={routes.browse.botb.discover} />
        </div>
      </div>
    </Frame>
  )
}

export default Landing;