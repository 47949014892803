import {FileUpload} from '../../types/file'
import {initUploadRequest, doUpload} from '../s3'
import {AxiosResponse} from 'axios'
import {config} from "../../config";

export function resolvePublicAsset(key: string) {
  return `https://${config.file.publicObjectUri}/${key}`
}

export async function uploadFiles(files: Array<FileUpload|File>, setUploadProgress?: (pct: number) => void) {
  const uploadRequestPromises: Array<Promise<any>> = []
  files.forEach((elm) => {
    uploadRequestPromises.push(initUploadRequest(elm.name!))
  })
  const uploadRequests = await Promise.all(uploadRequestPromises)
  if (setUploadProgress) {
    setUploadProgress(20)
  }

  const uploadActionPromises: Array<Promise<AxiosResponse>> = []
  for (let i = 0; i < files.length; i++) {
    // @ts-ignore - used for type separation
    const fileBlob = files[i]?.data || files[i]
    const signedUploadRequest = uploadRequests[i]

    uploadActionPromises.push(doUpload(fileBlob, signedUploadRequest))
  }

  const uploadResults = await Promise.all(uploadActionPromises)
  if (setUploadProgress) {
    setUploadProgress(90)
  }

  const ans = []
  for (let i = 0; i < files.length; i++) {
    ans.push({
      file: files[i],
      request: uploadRequests[i],
      result: uploadResults[i],
    })
  }

  if (setUploadProgress) {
    setUploadProgress(100)
  }
  return ans
}