import axios from 'axios'
import { config } from '../../config'

const client = axios.create({withCredentials: true, baseURL: `${config.api.gateway}/v0`})

export async function initUploadRequest(fileName: string): Promise<any> {
  const encodedName = encodeURIComponent(fileName)
  return (await client.get(`/file/new/${encodedName}`)).data
}

// TODO: Better typing
export async function doUpload(file: Blob, config: any) {
  console.log(config)
  return axios.post(
    config.url,
    {...config.fields, file: file},
    {headers: {'Content-Type': 'multipart/form-data'}},
  )
}