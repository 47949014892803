import './VoteSharePage.style.scss'
import Button from "../../components/button/button.component";
import React, {useEffect, useState} from "react";
import {
  getAvailableVotes,
  getProfileByUuid,
  getUuidFromVanityTag,
  voteForProfile,
  whoami
} from "../../utils/communicator";
import {useParams} from "react-router-dom";
import {Skeleton} from "@mui/material";
import {whoamiResponseType} from "../../types";
import Profile from "../../types/profile.types";
import {profileIsBotbCreator} from "../../utils/utils";
import routes from "../index";
import {VoteButton} from "../../components/vote-widget/vote-widget.component";
import SendIcon from "@mui/icons-material/Send";
import {RWebShare} from "react-web-share";
import PoweredByDj3n from '../../assets/imgs/powered-by-dj3n-black.png'
import PoconosPark from '../../assets/imgs/botb/botb-vote-poconos.png'
import LogoReel from '../../assets/imgs/botb/botb-vote-logo-reel.png'
import DefaultProfileImage from '../../assets/imgs/botb/botb-default-vote-avatar.jpg'

enum TriState {
  none,
  yes,
  no,
}

export default function VoteSharePage() {
  const [cover, setCover] = useState<string>()
  const [name, setName] = useState<string>('')
  const [ellipses, setEllipses] = useState(1)
  const [hasVote, setHasVote] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [session, setSession] = useState<whoamiResponseType>()
  const [isAuthenticated, setIsAuthenticated] = useState<TriState>(TriState.none)
  const [activeProfile, setActiveProfile] = useState<Profile>()
  const [preferredShareUrl, setPreferredShareUrl] = useState('')

  const {handle, uuid} = useParams()

  useEffect(() => {
    if (uuid) {
      getProfileByUuid(uuid)
        .then((p) => setActiveProfile(p))
        .catch((e) => console.error('failed to get profile by uuid', e))
    } else if (handle) {
      getUuidFromVanityTag(handle)
        .then((id) => id ? getProfileByUuid(id) : null)
        .then((p) => p ? setActiveProfile(p) : null)
        .catch((e) => console.error('failed to get profile from handle', e))
    } else if (session) {
      setActiveProfile(session.profile)
    }
  }, [handle, uuid, session])

  useEffect(() => {
    whoami()
      .then((s) => {
        setSession(s)
        setIsAuthenticated(TriState.yes)
      })
      .catch((e) => {
        console.error(e)
        setIsAuthenticated(TriState.no)
      })
  }, [setSession])

  useEffect(() => {
    if (!activeProfile) return
    const p = activeProfile
    setCover(p.profileImage || DefaultProfileImage)
    if (p.name) setName(p.name)
    else if (p.handle) setName(p.handle)

    if (p.handle && profileIsBotbCreator(p)) {
      setPreferredShareUrl(`https://battleofthebands.com/${p.handle}/vote`)
    } else if (p.handle) {
      setPreferredShareUrl(`https://${window.location.host}${routes.browse.profile.vote.byHandle(p.handle)}`)
    } else if (p.uuid) {
      setPreferredShareUrl(`https://${window.location.host}${routes.browse.profile.vote.byUuid(p.uuid)}`)
    }
  }, [activeProfile])

  useEffect(() => {
    if (preferredShareUrl === window.location.href) return
    if (!preferredShareUrl.includes(window.location.host)) {
      window.history.replaceState(null, '', `https://${window.location.host}${routes.browse.profile.vote.byHandle(activeProfile?.handle!)}`)
      return;
    }
    window.history.replaceState(null, '', preferredShareUrl)
  }, [preferredShareUrl])

  useEffect(() => {
    if (isAuthenticated === TriState.none) return
    if (isAuthenticated === TriState.no) {
      setIsLoading(false)
      return
    }
    getAvailableVotes()
      .then((c) => {
      console.log('availableVotes', c)
      const b = c > 0
      console.log('hasVote', b)
      setHasVote(b)
    }).catch((e) => {
      console.error(e)
    }).finally(() => {
      setIsLoading(false)
    })
  }, [isAuthenticated, setHasVote, setIsLoading])

  useEffect(() => {
    if (!isLoading) return
    setTimeout(() => {
      setEllipses(ellipses + 1)
    }, 500)
  }, [isLoading, setEllipses, ellipses])

  function doVote() {
    if (isAuthenticated === TriState.no) window.location.href = '/auth'
    voteForProfile(activeProfile?.uuid!)
      .then(() => setHasVote(false))
      .catch((e) => {
        console.error(e)
        alert('Something went wrong during voting. Please refresh and try again.')
      });
  }

  let coverDiv = <Skeleton className='cover' variant='rectangular' />
  if (cover) {
    coverDiv = <div className='cover' style={{backgroundImage: `url(${cover})`}}></div>
  }

  return (
    <div className='dj3n-vote-share'>
      {coverDiv}
      <div className='content'>
        <div className='logo-reel'>
          <img src={LogoReel} />
        </div>
        <div className='title'>
          Vote for <span className='name'>{name || '.'.repeat(ellipses % 3 + 1) + '\u00a0'.repeat(2 - ellipses % 3)}</span> to open for Lynyrd Skynyrd and The Marshall Tucker Band
        </div>
        <div className='sub'>
          One lucky band will have the chance to open for Lynard Skynyrd and The Marshall Tucker Band playing Poconos Park on May 19!
        </div>
      </div>
      <div className='actions'>
        <VoteButton btnClass='' profile={activeProfile?.uuid!} whoami={session!} label={isLoading ? '.'.repeat(ellipses % 3 + 1) : undefined} />
        <Button className='btn-lost-highway-red' label='Get Tickets' href={routes.external.botb.buyTickets} />
        <RWebShare
          data={{
            url: preferredShareUrl,
            title: "Share",
          }}
          onClick={() => navigator.clipboard.writeText(preferredShareUrl)}
        >
          <Button className='btn-lost-highway-yellow' label='Share' />
        </RWebShare>
        <div className='submit-band-action'><a href={routes.external.botb.home}>Submit Your Band</a></div>
      </div>
      <div className='powered-by'>
        <img src={PoconosPark} alt='Poconos Park' />
        <a href='/'><img src={PoweredByDj3n} alt='powered by DJ3N' /></a>
      </div>
    </div>
  )
}