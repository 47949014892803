export const config = {
    api: {
        gateway: window.REACT_APP_GATEWAY,
    },
    file: {
        publicObjectUri: window.REACT_APP_FILE_OBJECT_URL,
    },
    wallet: {
        base: window.REACT_APP_SMS_WALLET_URL,
        routes: {
            export: `${window.REACT_APP_SMS_WALLET_URL}/export`
        }
    },
}
