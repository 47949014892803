import Frame from "../../components/frame";
import Button, {CancelButton} from "../../components/button/button.component";

import BotbTypeface from '../../assets/imgs/botb/botb-typeface.png'
import OpenForAd from '../../assets/imgs/botb/open-for-ad.png'
import LostHighwayConcert from '../../assets/imgs/botb/lost-highway-concert-and-motorcycle.png'

import './botb.style.scss'
import routes from "../index";

export default function BattleOfTheBandsLanding({}) {
  return (
    <Frame className='dj3n-botb-landing' disableMenu action={
      <CancelButton className='alt-action-header' label='Just Look Around' href='/' />
    }>
      <div className='content'>
        <div className='block signup'>
          <img className='botb-typeface' src={BotbTypeface} alt='Battle of the Bands' />
          <img className='open-for-ad' src={OpenForAd} alt='Your band can open for Lynard Skinard and the Marshall Tucker Band. Enter the Battle of the Bands Contest today.' />
          <Button className='primary-action accent' label='Sign Up' href={routes.botb.signup} />
        </div>
        <div className='block vote'>
          <Button className='primary-action accent secondary' label='Vote' href='/' />
          <img className='light-highway' src={LostHighwayConcert} alt='Lost Highway Motorcycle Show & Concert; May 19-21, 2023; Poconos Park, Bushkill, PA' />
        </div>
      </div>
    </Frame>
  )
}
