import ReactSelect, {GroupBase, Props} from "react-select";

import './style.scss';

export default function Select<
    Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
    >(props: Props<Option, IsMulti, Group>) {
  return (
    <ReactSelect {...props} className={`dj3n-select ${props.className}`} theme={(theme) => ({ ...theme, borderRadius: 0 })} />
  );
}
