import './botb-ad.styles.scss'
import React, {MouseEventHandler, useState} from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import routes from "../../routes";

export default function BotbAd() {
  const [expanded, setExpanded] = useState(false)

  function handleClick() {
    if (!expanded) {
      setExpanded(true)
    } else {
      window.open(routes.external.botb.home, '_blank')
    }
  }

  function toggleExpand(evt: any) {
    evt.stopPropagation()
    setExpanded(!expanded)
  }

  const ExpandIcon = expanded ? KeyboardArrowUpIcon : KeyboardArrowDownIcon

  return (
    <div className={`botb-advert ${expanded ? 'expanded' : ''}`} onClick={handleClick}>
      <div className='overlay' onClick={toggleExpand}><ExpandIcon /></div>
    </div>
  )
}