import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import { isPossiblePhoneNumber, Value as PhoneNumber } from "react-phone-number-input";
import PhoneInput from '../../components/input/phone-number-input.component'

import { smsLoginHandler } from "../../utils/sms-wallet/sms-wallet.utils";
import Button from "../../components/button/button.component";
import Frame from "../../components/frame";
import {Checkbox, FormControlLabel, FormGroup} from "@mui/material";

import "./login.styles.scss";
import {useSearchParams} from "react-router-dom";
import routes from "../index";

export function Asterisk() {
  return (
    <span className='asterisk-required'>*</span>
  )
}

const Login = () => {
  const [phone, setPhone] = useState<PhoneNumber>();
  const [connecting, setConnecting] = useState(false);
  const [tosCheck, setTosCheck] = useState(false);
  const [smsCheck, setSmsCheck] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const url = searchParams.get('url') || undefined;
  const page = searchParams.get('page') || undefined;

  const loginHandler = async () => {
    try {
      if (!phone) return
      setConnecting(true);
      await smsLoginHandler(phone.toString(), page, url);
    } catch (e) {
      console.log("Cannot login:", e);
    } finally {
      setConnecting(false);
    }
  };

  const verifyPhone = (): boolean | undefined => {
    if (phone && tosCheck && smsCheck)
      return isPossiblePhoneNumber(phone!) && !connecting;
    return false;
  };

  function tosOnChange(e: ChangeEvent<HTMLInputElement>, b: boolean) {
    setTosCheck(b)
  }

  function smsOnChange(e: ChangeEvent<HTMLInputElement>, b: boolean) {
    setSmsCheck(b)
  }

  return (
    <Frame>
      <div className="login">
        <h2>Login with <span className='typeface'>DJ3N</span></h2>

        <PhoneInput
          defaultCountry='US'
          placeholder='(###) ###-####'
          name={'phone-input'}
          value={phone} onChange={setPhone}
        />

        <div className='tos-agreement'>
          <span>By clicking confirm I agree to the following</span>
          <FormGroup>
            <FormControlLabel control={<Checkbox required onChange={tosOnChange} checked={tosCheck} />} label={<span><a href='https://home.dj3n.com/terms-of-service/' target='_blank'>Terms of Service</a> and <a href='https://home.dj3n.com/privacy-policy/' target='_blank'>Privacy Policy</a><Asterisk /></span>} />
            <FormControlLabel control={<Checkbox onChange={smsOnChange} checked={smsCheck} required />} label={<span><a href='https://home.dj3n.com/sms-opt-in/' target='_blank'>SMS Opt-In</a><Asterisk /></span>} />
          </FormGroup>
        </div>

        <Button className='primary-action' label='Login' onClick={loginHandler} disabled={!verifyPhone()} />

        <div className='dj3n-trust'>
          Only trust dj3n.com or dj3n.io when logging in<br />
          <a href={routes.external.phishing} target='_blank'>Learn more</a>
        </div>
      </div>
    </Frame>
  );
};

export default Login;
