import {ScrollElement} from "../../components/horizontal-scroller";
import React, {useEffect, useState} from "react";
import Frame from "../../components/frame";

import './browse.styles.scss'
import Profile from "../../types/profile.types";

export default function Browse({title, queryFn}: {title: string, queryFn: () => Promise<Profile[]>}) {
  const [results, setResults] = useState<Profile[]>([])

  const scrollElementWidth = 169;
  const scrollElementHeight = 113;

  useEffect(() => {
    queryFn()
      .then(r => setResults(r))
      .catch((e) => console.error('Failed to fetch results', e))
  }, [queryFn, setResults])

  return (
    <Frame className='browse'>
      <h3 className='dj3n-section-header'>{title}</h3>
      <div className='results'>
        { results?.map((x) =>
          <ScrollElement
            image={x.profileImage}
            width={scrollElementWidth} height={scrollElementHeight}
            description={`3/${x.handle}`}
            link={`/nfts/gallery/${x.uuid}`}
            key={x.uuid}
          />
        )
        }
      </div>
    </Frame>
  )
}