export enum Provider {
  CUSTOM = 'custom',
  AMAZON_MUSIC = 'amazon_music',
  APPLE_APP_STORE = 'apple_app_store',
  APPLE_BOOKS = 'apple_books',
  APPLE_MUSIC = 'apple_music',
  APPLE_PODCASTS = 'apple_podcasts',
  AUDIBLE = 'audible',
  CAMEO = 'cameo',
  DISCORD = 'discord',
  ENS = 'ens',
  FACEBOOK = 'facebook',
  GOOGLE_PLAY = 'google_play',
  GRUBHUB = 'grubhub',
  INSTAGRAM = 'instagram',
  KICKSTARTER = 'kickstarter',
  LINKEDIN = 'linkedin',
  OPENSEA = 'opensea',
  OPENTABLE = 'opentable',
  PINTEREST = 'pinterest',
  REDBUBBLE = 'redbubble',
  REDDIT = 'reddit',
  SHOPIFY = 'shopify',
  SNAPCHAT = 'snapchat',
  SOUNDCLOUD = 'soundcloud',
  SPOTIFY = 'spotify',
  TELEGRAM = 'telegram',
  TIDAL = 'tidal',
  TIKTOK = 'tiktok',
  TUMBLR = 'tumblr',
  TWITCH = 'twitch',
  TWITTER = 'twitter',
  UBER_EATS = 'uber_eats',
  VIMEO = 'vimeo',
  YOUTUBE = 'youtube',
  YOUTUBE_MUSIC = 'youtube_music',
  ZAZZLE = 'zazzle',
}

export const linkTypes = {
  [Provider.AMAZON_MUSIC]: {
    image: 'amazon_music_icon.png',
    label: 'Amazon Music',
    urls: ['music.amazon.com'],
  },
  [Provider.APPLE_APP_STORE]: {
    image: 'apple_app_store_icon.png',
    label: 'Apple App Store',
    urls: ['apps.apple.com'],
  },
  [Provider.APPLE_BOOKS]: {
    image: 'apple_books_icon.png',
    label: 'Apple Books',
    urls: ['books.apple.com'],
  },
  [Provider.APPLE_MUSIC]: {
    image: 'apple_music_icon.png',
    label: 'Apple Music',
    urls: ['music.apple.com'],
  },
  [Provider.APPLE_PODCASTS]: {
    image: 'apple_podcasts_icon.png',
    label: 'Apple Podcasts',
    urls: ['podcasts.apple.com'],
  },
  [Provider.AUDIBLE]: {
    image: 'audible_icon.png',
    label: 'Audible',
    urls: ['audible.com'],
  },
  [Provider.CAMEO]: {
    image: 'cameo_icon.png',
    label: 'Cameo',
    urls: ['cameo.com'],
  },
  [Provider.DISCORD]: {
    image: 'discord_icon.png',
    label: 'Discord',
    urls: ['discord.gg'],
  },
  [Provider.ENS]: {
    image: 'ens_icon.png',
    label: 'ENS',
    urls: ['ens.domains.com', '.eth.xyz'],
  },
  [Provider.FACEBOOK]: {
    image: 'facebook_icon.png',
    label: 'Facebook',
    urls: ['facebook.com', 'fb.watch'],
  },
  [Provider.GOOGLE_PLAY]: {
    image: 'google_play_icon.png',
    label: 'Google Play',
    urls: ['play.google.com'],
  },
  [Provider.GRUBHUB]: {
    image: 'grubhub_icon.png',
    label: 'Grubhub',
    urls: ['grubhub.com'],
  },
  [Provider.INSTAGRAM]: {
    image: 'instagram_icon.png',
    label: 'Instagram',
    urls: ['instagram.com'],
  },
  [Provider.KICKSTARTER]: {
    image: 'kickstarter_icon.png',
    label: 'Kickstarter',
    urls: ['kickstarter.com'],
  },
  [Provider.LINKEDIN]: {
    image: 'linkedin_icon.png',
    label: 'LinkedIn',
    urls: ['linkedin.com', 'linkedin.com/in/'],
  },
  [Provider.OPENSEA]: {
    image: 'opensea_icon.png',
    label: 'OpenSea',
    urls: ['opensea.io'],
  },
  [Provider.OPENTABLE]: {
    image: 'opentable_icon.png',
    label: 'OpenTable',
    urls: ['opentable.com'],
  },
  [Provider.PINTEREST]: {
    image: 'pintrest_icon.png',
    label: 'Pintrest',
    urls: ['pintrest.com'],
  },
  [Provider.REDBUBBLE]: {
    image: 'redbubble_icon.png',
    label: 'Redbubble',
    urls: ['redbubble.com'],
  },
  [Provider.REDDIT]: {
    image: 'reddit_icon.png',
    label: 'Reddit',
    urls: ['reddit.com', 'reddit.com/r/'],
  },
  [Provider.SHOPIFY]: {
    image: 'shopify_icon.png',
    label: 'Shopify',
    urls: ['shopify.com'],
  },
  [Provider.SNAPCHAT]: {
    image: 'snapchat_icon.png',
    label: 'Snapchat',
    urls: ['snapchat.com', 'story.snapchat.com'],
  },
  [Provider.SOUNDCLOUD]: {
    image: 'soundcloud_icon.png',
    label: 'SoundCloud',
    urls: ['soundcloud.com', 'on.soundcloud.com'],
  },
  [Provider.SPOTIFY]: {
    image: 'spotify_icon.png',
    label: 'Spotify',
    urls: ['open.spotify.com', 'spotify.com'],
  },
  [Provider.TELEGRAM]: {
    image: 'telegram_icon.png',
    label: 'Telegram',
    urls: ['telegram.com', 't.me/'],
  },
  [Provider.TIDAL]: {
    image: 'tidal_icon.png',
    label: 'Tidal',
    urls: ['tidal.com'],
  },
  [Provider.TIKTOK]: {
    image: 'tiktok_icon.png',
    label: 'TikTok',
    urls: ['tiktok.com'],
  },
  [Provider.TUMBLR]: {
    image: 'tumblr_icon.png',
    label: 'Tumblr',
    urls: ['tumblr.com', 'at.tumblr.com', 't.tumblr.com'],
  },
  [Provider.TWITCH]: {
    image: 'twitch_icon.png',
    label: 'Twitch',
    urls: ['twitch.com'],
  },
  [Provider.TWITTER]: {
    image: 'twitter_icon.png',
    label: 'Twitter',
    urls: ['twitter.com'],
  },
  [Provider.UBER_EATS]: {
    image: 'ubereats_icon.png',
    label: 'Uber Eats',
    urls: ['ubereats.com'],
  },
  [Provider.VIMEO]: {
    image: 'vimeo_icon.png',
    label: 'Vimeo',
    urls: ['vimeo.com'],
  },
  [Provider.YOUTUBE_MUSIC]: {
    image: 'youtube_music_icon.png',
    label: 'YouTube Music',
    urls: ['music.youtube.com'],
  },
  [Provider.YOUTUBE]: {
    image: 'youtube_icon.png',
    label: 'YouTube',
    urls: ['youtube.com', 'youtu.be'],
  },
  [Provider.ZAZZLE]: {
    image: 'zazzle_icon.png',
    label: 'Zazzle',
    urls: ['zazzle.com'],
  },
  [Provider.CUSTOM]: {
    image: 'custom.png',
    label: 'Custom',
    urls: [],
  },
};

export default linkTypes;
