import {InputAdornment, TextField as MuiTextField, TextFieldProps as MuiTextFieldProps} from '@mui/material';

import './style.scss';
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import {getUuidFromVanityTag} from "../../utils/communicator";

export default function TextField(args: MuiTextFieldProps) {
  const classNameOverride = `dj3n-text-input ${args.className || ''}`;

  let inputProps = {disableUnderline: true}
  if (args?.InputProps) {
    inputProps = {...inputProps, ...args.InputProps}
  }

  return (
    // @ts-ignore
    <MuiTextField variant='filled'  {...args} InputProps={inputProps} className={classNameOverride}/>
  );
}

export type TextFieldHandleProps = MuiTextFieldProps & {
  InputProps?: any,
  setIsValid?: Dispatch<SetStateAction<boolean>>,
  setIsLoading?: Dispatch<SetStateAction<boolean>>,
  setHandle?: Dispatch<SetStateAction<string>> | ((v: string) => any),
  value?: string
  whitelist?: string[]
}

export function TextFieldHandle({
                                  setIsValid,
                                  setIsLoading,
                                  value: handle,
                                  whitelist,
                                  setHandle,
                                  onChange: callerOnChange,
                                  ...args
                                }: TextFieldHandleProps) {
  const [errorMsg, setErrorMsg] = useState('')

  useEffect(() => {
    if (!handle) {
      setIsValid?.(false)
      return
    } else if (handle.length < 3) {
      setIsValid?.(false)
      setErrorMsg('too short')
    }
    if (whitelist?.includes(handle)) {
      setIsValid?.(true)
      setIsLoading?.(false)
      return
    }
    setIsLoading?.(true)
    const timer = setTimeout(checkHandle, 300)
    return () => {
      clearTimeout(timer)
    }
  }, [handle, setIsLoading, setIsValid])

  function checkHandle() {
    if (!handle || handle.length < 3) return
    getUuidFromVanityTag(handle)
      .then((r) => {
        setIsValid?.(!r)
        if (r) {
          setErrorMsg('That username is unavailable')
        } else {
          setErrorMsg('')
        }
      })
      .catch((e) => {
        console.error(e)
        setErrorMsg(e.message)
      })
      .finally(() => setIsLoading?.(false))
  }

  function onChange(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const v = e.target.value
    setHandle?.(v.replace(/\W/g, ''))
    callerOnChange?.(e)
  }

  const InputProps = {
    startAdornment: <InputAdornment position='start'>3/</InputAdornment>,
    ...args?.InputProps,
  }
  return <TextField error={!!errorMsg} helperText={errorMsg} {...args} onChange={onChange} value={handle} InputProps={InputProps} />;
}